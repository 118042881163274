import React, { useEffect } from 'react';

import { Flex, PrimaryButton, Text } from 'flicket-ui';
import { useRouter } from 'next/router';

import { Footer, Seo, Menu } from '~components';
import { Layout } from '~components/common/Layout';

const NotFound = () => {
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/');
  }, []);

  return (
    <>
      <Seo title="Not found" description="Not found page for" />
      <Menu>
        <Text
          fontSize={7}
          lineHeight="medium"
          fontWeight="heavy"
          color="white"
          variant="capitalize"
          zIndex={2}
          mt={'auto' as any}
        >
          Error
        </Text>
      </Menu>
      <Layout layoutType="main">
        <Flex
          w="100%"
          alignItems="center"
          flexDir="column"
          justifyContent={{ _: 'flex-start', md: 'center' }}
          flex="1"
          px={{ _: 2, md: 4 }}
          py={2}
          bg="N100"
        >
          <Flex
            alignItems="center"
            flexDir="column"
            justifyContent="center"
            w="100%"
            maxW="480px"
            bg="white"
            px={{ _: 2, md: 4 }}
            py={4}
            borderRadius="lg"
            boxShadow="container"
          >
            <Text
              fontWeight="demiBold"
              textAlign="center"
              fontSize={6}
              mb={{ _: 0, md: 1 }}
            >
              404
            </Text>
            <Text textAlign="center">
              We could not find the page you were looking for.
            </Text>
            <PrimaryButton to="/" mt={{ _: 2, lg: 3 }}>
              Back to main site
            </PrimaryButton>
          </Flex>
        </Flex>
        <Footer />
      </Layout>
    </>
  );
};

export default NotFound;
